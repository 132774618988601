<template>
	<div>
		<div class="vx-row">
			<!-- Statistics Card -->
			<div class="vx-col w-full xl:w-1/4 mb-base">
				<vx-card slot="no-body">
					<div class="p-6 flex justify-between flex-row-reverse items-center">
						<span class="p-4 inline-flex rounded-full feather-icon select-none relative text-primary" style="background: rgba(var(--vs-primary),0.1);"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-monitor"><rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect><line x1="8" y1="21" x2="16" y2="21"></line><line x1="12" y1="17" x2="12" y2="21"></line></svg></span>
						<div>
							<h2 class="mb-1 font-bold">{{ requestorStats['requestsCount'] | formatNumber }}</h2>
							<span>Total Requests<br>Count</span>
						</div>
					</div>
				</vx-card>
			</div>
			<!-- Statistics Card -->
			<div class="vx-col w-full xl:w-1/4 mb-base">
				<vx-card slot="no-body">
					<div class="p-6 flex justify-between flex-row-reverse items-center">
						<span class="p-4 inline-flex rounded-full feather-icon select-none relative text-dark" style="background: rgba(var(--vs-dark),0.1);"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-triangle"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12" y2="17"></line></svg></span>
						<div>
							<h2 class="mb-1 font-bold">{{ requestorStats['manualUnsuspensionCount'] | formatNumber }}</h2>
							<span>Manual Unsuspension<br>Count</span>
						</div>
					</div>
				</vx-card>
			</div>
			<!-- Statistics Card -->
			<div class="vx-col w-full xl:w-1/4 mb-base">
				<vx-card slot="no-body">
					<div class="p-6 flex justify-between flex-row-reverse items-center">
						<span class="p-4 inline-flex rounded-full feather-icon select-none relative text-danger" style="background: rgba(var(--vs-danger),0.1);"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-info "><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12" y2="8"></line></svg></span>
						<div>
							<h2 class="mb-1 font-bold">{{ requestorStats['autoUnsuspendedRequests'] | formatNumber }}</h2>
							<span>Auto Unsuspended<br>Requests</span>
						</div>
					</div>
				</vx-card>
			</div>
			<!-- Statistics Card -->
			<div class="vx-col w-full xl:w-1/4 mb-base">
				<vx-card slot="no-body">
					<div class="p-6 flex justify-between flex-row-reverse items-center">
						<span class="p-4 inline-flex rounded-full feather-icon select-none relative text-warning" style="background: rgba(var(--vs-warning),0.1);"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-pause-circle"><circle cx="12" cy="12" r="10"></circle><line x1="10" y1="15" x2="10" y2="9"></line><line x1="14" y1="15" x2="14" y2="9"></line></svg></span>
						<div>
							<h2 class="mb-1 font-bold">{{ requestorStats['nonAutoUnsuspendedRequests'] | formatNumber }}</h2>
							<span>Non Auto Unsuspended<br>Requests</span>
						</div>
					</div>
				</vx-card>
			</div>
			<!-- Statistics Card -->
			<div class="vx-col w-full xl:w-1/2 mb-base">
				<vx-card slot="no-body">
					<div class="p-6">
						<div class="vx-row">
							<div class="vx-col w-full xl:w-1/2 mb-1 xl:mb-0">
								<span>First Request At</span>
							</div>
							<div class="vx-col w-full xl:w-1/2 xl:text-right">
								<h5 class="mb-1 font-bold">{{ requestorStats['firstRequestAt'] }}</h5>
							</div>
						</div>
					</div>
				</vx-card>
			</div>
			<!-- Statistics Card -->
			<div class="vx-col w-full xl:w-1/2 mb-base">
				<vx-card slot="no-body">
					<div class="p-6">
						<div class="vx-row">
							<div class="vx-col w-full xl:w-1/2 mb-1 xl:mb-0">
								<span>Last Request At</span>
							</div>
							<div class="vx-col w-full xl:w-1/2 xl:text-right">
								<h5 class="mb-1 font-bold">{{ requestorStats['lastRequestAt'] }}</h5>
							</div>
						</div>
					</div>
				</vx-card>
			</div>
		</div>

		<!-- Request List -->
		<vx-card title="Request List">
			<div slot="no-body" class="p-6 pt-0">
				<ag-grid-vue
					:gridOptions="gridOptions"
					class="ag-theme-material w-100 mb-4 ag-grid-table ag-grid-table--small"
					:columnDefs="columnDefs"
					:defaultColDef="defaultColDef"
					:rowData="tableData"
					rowSelection="multiple"
					:enableRangeSelection="true"
					:suppressCopyRowsToClipboard="true"
					colResizeDefault="shift"
					:suppressDragLeaveHidesColumns="true"
					:animateRows="false"
					:floatingFilter="true"
					:pagination="true"
					:paginationPageSize="paginationPageSize"
					:suppressPaginationPanel="true"
					:suppressRowClickSelection="true"
					:rowClassRules="rowClassRules"
					:statusBar="statusBar"
					:sideBar="sideBar"
					:rowHeight="38"
					:getContextMenuItems="getContextMenuItems"
					@column-resized="onColumnResized">
				</ag-grid-vue>
				<div class="vx-row">
					<div class="vx-col w-full">
						<vs-pagination
						:total="totalPages"
						:max="maxPageNumbers"
						v-model="currentPage" />
					</div>
				</div>
			</div>
		</vx-card>
	</div>
</template>

<script>
	import { AgGridVue } from "ag-grid-vue"
	import "ag-grid-enterprise";

	import '@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss'

	export default {
		components: {
			AgGridVue,
		},
		props: {
			paramEmail: {
				type: String | Number,
				default: null
			},
		},
		data() {
			return {
				requestorStats: {
					requestsCount: 0,
					autoUnsuspendedRequests: 0,
					firstRequestAt: '',
					lastRequestAt: '',
					nonAutoUnsuspendedRequests: 0,
					manualUnsuspensionCount: 0
				},
				gridOptions: {},
				maxPageNumbers: 7,
				gridApi: null,
				defaultColDef: {
					sortable: true,
					resizable: true,
					suppressMenu: true,
					suppressMovable: false,
					filterParams: {
						suppressAndOrCondition: true
					},
				},
				rowClassRules: null,
				statusBar: null,
				sideBar: null,
				columnDefs: [
				{
					headerName: 'ID',
					field: 'id',
					minWidth: 100,
					filter: "agTextColumnFilter",
					hide: true,
				},
				{
					headerName: 'Domain',
					field: 'domain',
					minWidth: 275,
					width: 275,
					filter: "agTextColumnFilter",
					pinned: 'left',
					cellRenderer: (data) => {
						if(data.value) {
							return `<a href="/search?domain_name=${data.value}" target="_blank">${data.value}</a>`
						}
						else {
							return '';
						}
					},
				},
				{
					headerName: 'Email',
					field: 'email',
					filter: "agTextColumnFilter",
					minWidth: 275,
				},
				{
					headerName: 'Name',
					field: 'name',
					filter: "agTextColumnFilter",
					minWidth: 200,
				},
				{
					headerName: 'Reason',
					field: 'reason',
					filter: "agTextColumnFilter",
					minWidth: 275,
				},
				{
					headerName: 'Source',
					field: 'source',
					filter: "agTextColumnFilter",
					minWidth: 275,
				},
				{
					headerName: 'Ticket ID',
					field: 'ticket_id',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				{
					headerName: 'Queued At',
					field: 'auto_unsuspension_queued_at',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
									comparator: function(filterLocalDateAtMidnight, cellValue) {
										if (cellValue === null) return -1;

										let cellDate = new Date(cellValue);
										
										let cellDateString = new Date(cellDate.toDateString());
										let filterLocalDateAtMidnightString = new Date(filterLocalDateAtMidnight.toDateString());
										
										if (cellDateString.getTime() === filterLocalDateAtMidnightString.getTime()) {
											return 0;
										}
										if (cellDate < filterLocalDateAtMidnight) {
											return -1;
										}
										if (cellDate > filterLocalDateAtMidnight) {
											return 1;
										}
									},
								}
							},
						],
					},
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					},
				},
				{
					headerName: 'Created At',
					field: 'created_at',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
									comparator: function(filterLocalDateAtMidnight, cellValue) {
										if (cellValue === null) return -1;

										let cellDate = new Date(cellValue);
										
										let cellDateString = new Date(cellDate.toDateString());
										let filterLocalDateAtMidnightString = new Date(filterLocalDateAtMidnight.toDateString());
										
										if (cellDateString.getTime() === filterLocalDateAtMidnightString.getTime()) {
											return 0;
										}
										if (cellDate < filterLocalDateAtMidnight) {
											return -1;
										}
										if (cellDate > filterLocalDateAtMidnight) {
											return 1;
										}
									},
								}
							},
						],
					},
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					},
					sort: 'desc',
				},
				{
					headerName: 'Updated At',
					field: 'updated_at',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
									comparator: function(filterLocalDateAtMidnight, cellValue) {
										if (cellValue === null) return -1;

										let cellDate = new Date(cellValue);
										
										let cellDateString = new Date(cellDate.toDateString());
										let filterLocalDateAtMidnightString = new Date(filterLocalDateAtMidnight.toDateString());
										
										if (cellDateString.getTime() === filterLocalDateAtMidnightString.getTime()) {
											return 0;
										}
										if (cellDate < filterLocalDateAtMidnight) {
											return -1;
										}
										if (cellDate > filterLocalDateAtMidnight) {
											return 1;
										}
									},
								}
							},
						],
					},
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					}
				},
				],
				tableData: [],
			}
		},
		watch: {
			windowWidth(val) {
				// Toggle colummn movable
				this.toggleColumnMovable(val);
				// Toggle colummn pinning
				this.toggleColumnPinned(val);
			},
			paramEmail(val) {
				// Table Data AXIOS Call
				this.populateTableData(val);
			},
		},
		computed: {
			windowWidth: function(){
				return this.$store.state.windowWidth;
			},
			paginationPageSize() {
				if(this.gridApi) {
					return this.gridApi.paginationGetPageSize()
				}
				else {
					return 500
				}
			},
			totalPages() {
				if(this.gridApi) {
					return this.gridApi.paginationGetTotalPages()
				}
				else {
					return 0
				}
			},
			currentPage: {
				get() {
					if(this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
						else return 1
					},
				set(val) {
					this.gridApi.paginationGoToPage(val - 1);
				}
			},
			user_role() {
				return JSON.parse(localStorage.getItem('userDetails')).role;
			},
		},
		methods: {
			toggleColumnMovable(val) {
				if(val <= 576) {
					this.defaultColDef['suppressMovable'] = true;
				}
				else {
					this.defaultColDef['suppressMovable'] = false;
				}
			},
			toggleColumnPinned(val) {
				if(val <= 576) {
					this.maxPageNumbers = 4;
					this.gridOptions.columnApi.setColumnPinned('domain', null);
				}
				else {
					this.gridOptions.columnApi.setColumnPinned('domain', 'left')
				}
			},
			onColumnResized() {
				this.gridApi.resetRowHeights();
			},
			updateSearchQuery(val) {
				this.gridApi.setQuickFilter(val);
			},
			sizeColumnsToFit() {
				this.gridApi.sizeColumnsToFit();
			},
			populateTableData(email) {
				let self = this;

				this.$axiosSecure.get('/get-unsuspension-requestor', {
					params: {
						email: email
					}
				})
				.then((response) => {
					// Assign Data
					this.tableData = response.data.requests;
					this.requestorStats['requestsCount'] = response.data.requests_count;
					this.requestorStats['manualUnsuspensionCount'] = response.data.manual_unsuspension_count;
					this.requestorStats['autoUnsuspendedRequests'] = response.data.auto_unsuspended_requests;
					this.requestorStats['nonAutoUnsuspendedRequests'] = response.data.non_auto_unsuspended_requests;
					this.requestorStats['firstRequestAt'] = response.data.first_request_at;
					this.requestorStats['lastRequestAt'] = response.data.last_request_at;

					// Refresh Unsuspension Requests
					this.$emit('refreshUnsuspensionRequests');
				})
				.catch((error) => {
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			},
			getContextMenuItems(params) {
				let result,
					self = this;

				if(params['column'] && params['column']['colId'] && params['column']['colId'] == 'domain') {
					result = [
						{
							name: "Resolve Domain(s)",
							action: function() {
								let rangeSelection = self.gridApi.getCellRanges(),
									value = params['value'];

								if(rangeSelection) {
									// Get selected cells & iterate through them to find domains
									rangeSelection.forEach(item => {
										let start = Math.min(item.startRow.rowIndex, item.endRow.rowIndex),
											end = Math.max(item.startRow.rowIndex, item.endRow.rowIndex),
											isRightClicked = false;
										
										for (let i = start; i <= end; i++) {
											if(self.gridApi.getDisplayedRowAtIndex(i)['data']['domain'] == value) {
												isRightClicked = true
											}
											window.open('https://anonymto.com/?http://' + self.gridApi.getDisplayedRowAtIndex(i)['data']['domain'], '_blank');
										}
										
										if(!isRightClicked) {
											window.open('https://anonymto.com/?http://' + value);
										}
									});
								}
								else {
									window.open('https://anonymto.com/?http://' + value);
								}
							},
						},
						'separator',
						'copy',
						'copyWithHeaders',
						'paste',
						'separator',
						'export'
					];
				}
				else {
					result = [
						'copy',
						'copyWithHeaders',
						'paste',
						'separator',
						'export'
					];
				}

				return result;
			}
		},
		beforeMount() {
			// Table Data AXIOS Call
			this.populateTableData(this.paramEmail);

			// Reset movable columns on mobile
			this.toggleColumnMovable(this.windowWidth);

			// Set Grid statusbar
			this.statusBar = {
				statusPanels: [
				{
					statusPanel: "agTotalRowCountComponent",
					align: "left"
				},
				{
					statusPanel: "agFilteredRowCountComponent",
					align: "right"
				}
				]
			};

			// Set Grid sidebar
			this.sideBar = {
				toolPanels: [
				{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel",
					toolPanelParams: {
						suppressRowGroups: true,
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressSideButtons: true,
						suppressColumnSelectAll: true,
						suppressColumnExpandAll: true
					}
				}
				],
			};
		},
		mounted() {
			this.gridApi = this.gridOptions.api;

			// Resize to fit columns
			this.sizeColumnsToFit();
		}
	}
</script>
